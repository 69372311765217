import React from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import LebronStretch from "./PDF_Images/Image1.jpeg";
import {Font} from '@react-pdf/renderer';
import MyCustomFont from './fonts/Anton-Regular.ttf';

Font.register({
  family: 'AntonFamily',
  src: MyCustomFont
})

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      padding: 0, // No padding to avoid extra space
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden', // Ensure no overflow creates extra space
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover', // Ensure the image covers the entire space
      zIndex:2,

    },
    text: {
      fontSize: 30,
      fontWeight:"bold",
      textAlign: 'justify',
      marginVertical: 10,
      // position:"absolute",
      // top:"20%",
      zIndex:999,
    },
  });
  
  // firstname: selectedStudent.firstname,
  // lastname: selectedStudent.lastname,
  // parentname: selectedStudent.parentname,
  // startDate: formatDate(selectedStudent.StartDate),
  // endDate: formatDate(selectedStudent.EndDate)
const PDFFile = ({data,courseId}) => {  
    const pages = [
        {
          name: data?.firstname||"name",
          image: LebronStretch,
          title: 'Certificate for Course Name',
          description: 'Awarded to John Doe for completing the course successfully.',
        },
      ];
    

  return (
    <Document>
      {pages.map((page, index) => {
        return (
          <Page key={index} style={{...styles.body}}>
          {/* <Text style={styles.header} fixed></Text> */}
          <Text style={styles.text}>
          {page.name}
          </Text>
          <Image style={styles.image} src={page.image} />
          
          
          
         
        </Page>
        )
      })}

    </Document>
    // <Document>
    //   {pages.map((page, index) => (
    //     <Page key={index} style={styles.page}>
    //       <Image src={page.image} style={styles.image} />

    //       {/* Text overlay on image */}
    //       <View style={styles.textContainer}>
    //         <Text style={styles.titleText}>{page.title}</Text>
    //         <Text style={styles.normalText}>{page.description}</Text>
    //       </View>
    //     </Page>
    //   ))}
    // </Document>
  );
};

export default PDFFile;