import React, { useState, useEffect, useContext } from "react";
import TeacherNav from "../Navbars/TeacherNav"; // Import TeacherNav component
import Footer from "../Footer"; // Assuming Footer is also needed
import { UserContext } from "../UserContext"; // Import UserContext for user data
import "./GradeProjectPage.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const GradeProjectPage = (props) => {
  const location = useLocation();

  const { courseName, courseId } = location.state || [0,0];
  const { userInfo, onLogout } = useContext(UserContext); // Get userInfo and onLogout from UserContext
  const [studentsData, setStudentsData] = useState([])
  // const students = [
  //   { id: 1, name: "Syeda Abeeha Fatima" },
  //   { id: 2, name: "Rabya Rehman" },
  //   { id: 3, name: "Saim Ahmed" },
  //   { id: 4, name: "Mustafa Nabeel" },
  //   { id: 5, name: "Zainab Gohar" },
  //   { id: 6, name: "Aarav Singh" },
  //   { id: 7, name: "Muhammad Abdul Basra Usman" },
  //   { id: 8, name: "Muhammad Rahim Iqbal" },
  // ];

  // const [progress, setProgress] = useState(
  //   students.map(() => ({
  //     lesson: { selectedProject: "", marks: 0, totalMarks: 0, viewLink: "" },
  //     module: { selectedProject: "", marks: 0, totalMarks: 0, viewLink: "" },
  //     complete: false,
  //   }))
  // );

  // const lessonProjects = ["Lesson Project 1", "Lesson Project 2", "Lesson Project 3"];
  // const moduleProjects = ["Module Project 1", "Module Project 2", "Module Project 3"];
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentsByCourse/${courseId}`)
      .then(response => {
        console.log("response.data.Students data:", response.data?.courseInfo)
        setStudentsData(response?.data?.courseInfo.map((item) => ({
          ...item, activeLessonIndex: 0, activeModuleIndex: 0,
          activeLessonValue: item?.lessonData?.[0]?.obtainedMArks
          , activeModuleValue: item?.moduleData?.[0]?.obtainedMArks
        })));
        // console.log(response)
      })
      .catch(error => {
        console.error('Error fetching Students:', error);
      });
  }, [courseId]);

  // useEffect(() => {
  //   const fetchProjectData = async (studentId, index) => {
  //     const lessonResponse = await fetch(`/api/lessonProject/${studentId}`);
  //     const moduleResponse = await fetch(`/api/moduleProject/${studentId}`);

  //     const lessonProject = await lessonResponse?.json();
  //     const moduleProject = await moduleResponse?.json();

  //     const updatedProgress = [...progress];
  //     updatedProgress[index].lesson.totalMarks = lessonProject.totalMarks;
  //     updatedProgress[index].lesson.viewLink = lessonProject.viewLink;
  //     updatedProgress[index].module.totalMarks = moduleProject.totalMarks;
  //     updatedProgress[index].module.viewLink = moduleProject.viewLink;
  //     setProgress(updatedProgress);
  //   };

  //   students.forEach((student, index) => {
  //     fetchProjectData(student.id, index);
  //   });
  // }, [students]);

  // const handleProjectSelect = (index, type, value) => {
  //   const updatedProgress = [...progress];
  //   updatedProgress[index][type].selectedProject = value;
  //   setProgress(updatedProgress);
  // };

  const handleMarksChangeLesson = (studentId, value) => {
    const updatedStudentData = studentsData.map((student) => {
      if (student.studentId == studentId) {
        return {
          ...student,
          activeLessonValue: parseInt(value),
        };
      }
      return student;
    });
    console.log(updatedStudentData)
    setStudentsData(updatedStudentData);
  };
  const handleMarksChangeModule = (studentId, value) => {
    const updatedStudentData = studentsData.map((student) => {
      if (student.studentId == studentId) {
        return {
          ...student,
          activeModuleValue: parseInt(value),
        };
      }
      return student;
    });
    console.log(updatedStudentData)
    setStudentsData(updatedStudentData);
  };

  const handleSave = async (index, studentId) => {
    // alert(index)
    const student = studentsData.filter((item, i) => (
      studentId == item?.studentId
    ))
    console.log("student ", student)

    const moduleMarks = student[0]?.activeModuleValue;
    const lessonMarks = student[0]?.activeLessonValue;

    const ModuleId = student[0]?.moduleData?.[student[0]?.activeModuleIndex]?.moduleProjectId;
    const lessonId = student[0]?.lessonData?.[student[0]?.activeLessonIndex]?.lessonProjectId;

    const ModuleTotalMarks = student[0]?.moduleData?.[student[0]?.activeModuleIndex]?.totalMarks;
    const LessonTotalMarks = student[0]?.lessonData?.[student[0]?.activeLessonIndex]?.totalMarks;

    if(moduleMarks>ModuleTotalMarks || lessonMarks>LessonTotalMarks){
      toast.error("Obtain Marks Can't be grater than the Total Marks");
      return

    }

    const formData = new FormData();
    formData.append("studentId", studentId);
    formData.append("ModuleId", moduleMarks);
    formData.append("lessonId", lessonMarks);
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/updateMarks`,
        { studentId, moduleMarks, lessonMarks, ModuleId, lessonId },
        // {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // }
      );
      toast.success("Marks Updated Successfully");

    } catch (error) {
      toast.error("Failed to Update Marks");
      console.error(error);
    }
    // const studentData = {
    //   studentId: students[index].id,
    //   lesson: progress[index].lesson,
    //   module: progress[index].module,
    //   complete: progress[index].complete,
    // };

    // try {
    //   const response = await fetch("/api/saveProjectData", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(studentData),
    //   });

    //   if (response.ok) {
    //     alert(`Data for ${students[index].name} saved successfully!`);
    //   } else {
    //     alert(`Failed to save data for ${students[index].name}.`);
    //   }
    // } catch (error) {
    //   alert(`Error saving data for ${students[index].name}: ${error.message}`);
    // }
  };
  const handleLessonIndexChange = (index, studentId, value = 0) => {
    const updatedStudentData = studentsData.map((student) => {
      if (student.studentId === studentId) {
        return {
          ...student,
          activeLessonIndex: index,
          activeLessonValue: value,
        };
      }
      return student;
    });
    console.log(updatedStudentData)

    setStudentsData(updatedStudentData);
  };
  const handleModuleIndexChange = (index, studentId, value = 0) => {
    const updatedStudentData = studentsData.map((student) => {
      if (student.studentId === studentId) {
        return {
          ...student,
          activeModuleIndex: index,
          activeModuleValue: value,
        };
      }
      return student;
    });
    console.log(updatedStudentData)

    setStudentsData(updatedStudentData);
  };
  useEffect(() => {

  }, [studentsData])
  return (
    <>
      {/* Teacher Navigation Bar */}
      <TeacherNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={onLogout} />

      {/* Page Content */}
      <div className="grade-project-page">
        {/* Render the selected course name at the top */}
        <h1 className="course-name">{courseName}</h1>

        <div className="student-list">
          {studentsData?.map((student, index) => (
            <div key={index} className="student-row">
              <div className="student-name">{index + 1} {student?.name}</div>
              <div className="project-inputs">
                <label>
                  Lesson Project
                  <select
                    id="lesson"
                    // value={progress[index]?.lesson.selectedProject}
                    // onChange={(e) => handleProjectSelect(index, "lesson", e.target.value)}
                    onChange={(e) => { handleLessonIndexChange((e.target.selectedIndex - 1), student?.studentId, student?.lessonData[student?.activeLessonIndex]?.obtainedMArks) }}
                  >
                    <option value="" disabled>Select Lesson Project</option>
                    {student.lessonData.map((project, i) => (
                      <option key={project?.lessonProjectId} value={project?.lessonProjectId}>{project?.lessonProjectName}</option>
                    ))}
                  </select>
                </label>
                {/* <a href={progress[index]?.lesson.viewLink} target="_blank" rel="noopener noreferrer"> */}
                <a href={student?.lessonData[student?.activeLessonIndex]?.projectFile || '#'} target="_blank" rel="noopener noreferrer">

                  View Project
                </a>
                <input
                  type="number"
                  // value={progress[index]?.lesson.marks}
                  defaultValue={student?.lessonData[student?.activeLessonIndex]?.obtainedMArks || 0}
                  onChange={(e) => handleMarksChangeLesson(student?.studentId, e.target.value)}
                  placeholder="Marks"
                  inputMode="numeric"
                  max={student?.lessonData[student?.activeLessonIndex]?.totalMarks}
                />
                {/* <span>/ {progress[index]?.lesson.totalMarks}</span> */}
                <span>/ {student?.lessonData[student?.activeLessonIndex]?.totalMarks}</span>

              </div>
              <div className="project-inputs">
                <label>
                  Module Project
                  <select
                    // value={progress[index]?.module.selectedProject}
                    id="module"
                    // onChange={(e) => handleProjectSelect(index, "module", e.target.value)}
                    onChange={(e) => { handleModuleIndexChange((e.target.selectedIndex - 1), student?.studentId, student?.moduleData[student?.activeModuleIndex]?.obtainedMArks) }}

                  >
                    <option value="" disabled>Select Module Project</option>
                    {student.moduleData.map((moduleProject, i) => (
                      <option key={moduleProject?.moduleProjectId} value={moduleProject?.moduleProjectId}>{moduleProject?.moduleProjectName}</option>
                    ))}
                  </select>
                </label>
                <a
                  // href={progress[index]?.module.viewLink}
                  href={student?.moduleData[student?.activeModuleIndex]?.projectFile || '#'}
                  target="_blank" rel="noopener noreferrer">
                  View Project
                </a>
                <input
                  type="number"
                  defaultValue={student?.moduleData[student?.activeModuleIndex]?.obtainedMArks || 0}
                  onChange={(e) => handleMarksChangeModule(student?.studentId, e.target.value)}
                  placeholder="Marks"
                  max={student?.moduleData[student?.activeModuleIndex]?.totalMarks}
                  inputMode="numeric"


                />
                <span>/ {student?.moduleData[student?.activeModuleIndex]?.totalMarks}</span>

                {/* <span>/ {progress[index]?.module.totalMarks}</span> */}
              </div>
              <button onClick={() => handleSave(index, student.studentId)}>Save</button>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default GradeProjectPage;
