import React, { useState, useContext, useEffect } from 'react';
import './PolymatchReg.css'; // Make sure to link the CSS file
import polymatchImage from './Polymatch.png';
import { UserContext } from '../UserContext';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';
import Footer from "../Footer";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Select from 'react-select'; // Import react-select
import countryList from 'react-select-country-list'; // Import react-select-country-list
import ReactFlagsSelect from "react-flags-select";

const PolymatchReg = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    parentName: '',
    whatsapp: '',
    email: '',
    address: '',
    city: '',
    country: '',
    childPhoto: null,
  });

  const [countries, setCountries] = useState([]); // New state for country list
  const [imagePreview, setImagePreview] = useState(null);


  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        response.json().then(userInfo => {
          setUserInfo(userInfo);
          if (userInfo?.usertype) {
            setUserRole(userInfo?.usertype);
          }
        });
      });
    };
    fetchData();

    // Set country list on component mount
    const options = countryList().getData();
    setCountries(options);
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const onLogout = () => {
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    setUserRole('guest');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setFormData({
            ...formData,
            childPhoto: file,
        });
        setImagePreview(URL.createObjectURL(file)); // Set image preview
    }
};


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create a FormData object to send data, including the file
    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('age', formData.age);
    formDataToSend.append('gender', formData.gender);
    formDataToSend.append('parentName', formData.parentName);
    formDataToSend.append('whatsapp', formData.whatsapp);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('country', formData.country);
    formDataToSend.append('childPhoto', formData.childPhoto);  // Append the file
  
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/polymatchregister`, {
        method: 'POST',
        body: formDataToSend, // Send FormData object
        headers: {
          // 'Content-Type': 'multipart/form-data' - Not needed, FormData sets this automatically
        },
      });
  
      if (response.ok) {
        alert("Registration successful!");
      } else {
        alert("Registration failed.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };
  

  const handleCountryChange = (selectedOption) => {
    setFormData({
      ...formData,
      country: selectedOption.label,
    });
  };

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  return (
    <>
      {/* Header/Nav */}
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav />}
        </>
      )}

      <div className="registration-container">
        <div className="registration-left">
          <img src={polymatchImage} alt="Polymatch Logo" className="polymatch-image" />
          <h1> Mental Math Competition 2024</h1>
        </div>

        <div className="registration-right">
          <form onSubmit={handleSubmit}>
            <h2>Register for Mental Math Competition being held on 9.Nov.2024</h2>
            <p>Please make sure to enter the correct details below as this will be used to generate the certificate of your child.</p>

            <input
              type="text"
              name="firstName"
              placeholder="First Name of Child"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name of Child"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
            <select
              name="age"
              value={formData.age}
              onChange={handleInputChange}
              required
            >
              <option value="">Age of Child</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
            </select>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              required
            >
              <option value="">Gender of Child</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>

            <input
              type="text"
              name="parentName"
              placeholder="Parent Name"
              value={formData.parentName}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="whatsapp"
              placeholder="WhatsApp Number"
              value={formData.whatsapp}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
              required
            />

            {/* Country Dropdown using react-select-country-list */}
            <Select
              options={countries}
              value={countries.find(country => country.label === formData.country)}
              onChange={handleCountryChange}
              placeholder="Choose your Country"
            />

            <div className="file-upload">
              <label htmlFor="childPhoto">Click to upload Child's picture</label>
              <input
                type="file"
                id="childPhoto"
                name="childPhoto"
                onChange={handlePhotoChange}
                required
              />
              {imagePreview && (
              <div className="image-preview">
              <img src={imagePreview} alt="Child Preview" className="preview-image" />
              </div>
               )}
            </div>

            <button type="submit" className="register-button">Register</button>
          </form>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default PolymatchReg;
